import { hasNames } from "../utils";
import { graphql } from "gatsby";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import { useRef, useState } from "react";



import Button from "../components/button";
import ContactUsFooter from "../components/contact-us-footer";
import Frame from "../components/frame";
import { HeadMeta } from "../components/head-meta";
import InteractiveScroll from "../components/interactive-scroll";
import InternalHero from "../components/internal-hero";
import QuoteCarousel from "../components/quote-carousel";
import TeamMember from "../components/team-member";
import TeamMemberModal from "../components/team-member-modal";



import * as com from "../styles/common.module.scss";
import * as grd from "../styles/grid.module.scss";
import * as styles from "../styles/team.module.scss";


export const Head = () => {
	return <HeadMeta title="Team" />;
};
const TeamPage = ({ data }) => {
	const heroRef = useRef(null);
	const quoteRef = useRef(null);
	const membersRef = useRef(null);
	const contactRef = useRef(null);
	const [activeFilter, setActiveFilter] = useState("Cynosure Partners");

	const [memberOpen, setMemberOpen] = useState(-1);

	const placements = ['Cynosure Partners', 'Board of Advisors', 'Business and Operations']

	const scrollableLocations = [
		{
			label: "Team",
			ref: heroRef,
		},
		{
			label: "Founder Message",
			ref: quoteRef,
		},
		{
			label: "Members",
			ref: membersRef,
		},
		{ label: "Footer", ref: contactRef },
	];

	return (
		<Frame>
			<InteractiveScroll locations={scrollableLocations} />
			<InternalHero
				ref={heroRef}
				eyebrow={"Team"}
				backdrop={
					<StaticImage
						src={`../images/heros/team.jpg`}
						alt="background image"
						aria-hidden="true"
						placeholder="blurred"
						layout="fullWidth"
						loading="eager"
					/>
				}>
				<h1>Experienced investors, operators, and collaborators.</h1>
				<p>
					We empower every member of our team to actively participate in our
					partnership-driven approach to investing. This enables us to bring our
					best thinking to every opportunity.
				</p>
			</InternalHero>
			{/* CONTENT_MISSING */}
			<QuoteCarousel
				ref={quoteRef}
				varient="white"
				quotes={[
					{
						image: (
							<StaticImage
								src={`../images/missing-person-light.png`}
								alt="M Emmett"
								placeholder="blurred"
								width={75}
								height={75}
								layout="fixed"
							/>
						),
						person: `M Emmet`,
						position: `Dirty Dogs | Founder & CEO `,
						bigText: `“Cynosure has a rich history, and that makes a difference for me as an entrepreneur.”`,
					},
				]}
			/>
			<h2 className={hasNames(com.centerlineHeader, styles.secondaryHeader)}>
				Our Team
			</h2>
			<div className={com.filterBox}>
				{
				placements
					.sort()
					.reverse()
					.map((placement) => {
						return (
							<Button
								type="filter"
								active={activeFilter === placement}
								onClick={() => {
									setActiveFilter(placement);
								}}>
								{placement}
							</Button>
						);
					})}
				<Button
				type="filter"
				active={activeFilter === ""}
				onClick={() => {
					setActiveFilter("");
				}}>
				Show All
			</Button>
			</div>
			{/* Show All */}
			<div className={grd.gridContainer} ref={membersRef}>
				{data.teamMembers.nodes.map((person, index) => {
					if (
						activeFilter !== ""
					) {
						return null;
					}
					person.frontmatter.name =
						person.frontmatter.first_name + " " + person.frontmatter.last_name;
					return (
						<TeamMember
							key={"team-member-" + person.id}
							className={hasNames(grd.col3, grd.colMd4, grd.colSm6)}
							image={
								<GatsbyImage
									image={getImage(person.frontmatter.image)}
									alt={person.frontmatter.name}
								/>
							}
							name={person.frontmatter.name}
							position={person.frontmatter.title_short}
							openFn={() => {
								setMemberOpen(index);
								document.body.classList.add("scrollLocked");
							}}
							closeFn={() => {
								setMemberOpen(-1);
								document.body.classList.remove("scrollLocked");
							}}
							nextFn={
								index + 1 === data.teamMembers.nodes.length
									? null
									: () => {
											setMemberOpen(index + 1);
									  }
							}
							prevFn={
								index === 0
									? null
									: () => {
											setMemberOpen(index - 1);
									  }
							}
							isOpen={index === memberOpen}>
							<TeamMemberModal
								name={person.frontmatter.name}
								position={person.frontmatter.title_long}
								linkedin={person.frontmatter.linkedin}
								image={
									<GatsbyImage
										image={getImage(person.frontmatter.image)}
										alt={person.frontmatter.name}
									/>
								}>
								<div
									dangerouslySetInnerHTML={{
										__html: person.html,
									}}></div>
							</TeamMemberModal>
						</TeamMember>
					);
				})}
			</div>
			{/* board */}
			<div className={grd.gridContainer} ref={membersRef}>
				{data.teamBoard.nodes.map((person, index) => {
					let groupName = (person.frontmatter.board)? 'Board of Advisors':null;
					if (
						activeFilter !== groupName
					) {
						return null;
					}
					person.frontmatter.name =
						person.frontmatter.first_name + " " + person.frontmatter.last_name;
					return (
						<TeamMember
							key={"team-member-" + person.id}
							className={hasNames(grd.col3, grd.colMd4, grd.colSm6)}
							image={
								<GatsbyImage
									image={getImage(person.frontmatter.image)}
									alt={person.frontmatter.name}
								/>
							}
							name={person.frontmatter.name}
							position={person.frontmatter.title_short}
							openFn={() => {
								setMemberOpen(index);
								document.body.classList.add("scrollLocked");
							}}
							closeFn={() => {
								setMemberOpen(-1);
								document.body.classList.remove("scrollLocked");
							}}
							nextFn={
								index + 1 === data.teamBoard.nodes.length
									? null
									: () => {
											setMemberOpen(index + 1);
									  }
							}
							prevFn={
								index === 0
									? null
									: () => {
											setMemberOpen(index - 1);
									  }
							}
							isOpen={index === memberOpen}>
							<TeamMemberModal
								name={person.frontmatter.name}
								position={person.frontmatter.title_long}
								linkedin={person.frontmatter.linkedin}
								image={
									<GatsbyImage
										image={getImage(person.frontmatter.image)}
										alt={person.frontmatter.name}
									/>
								}>
								<div
									dangerouslySetInnerHTML={{
										__html: person.html,
									}}></div>
							</TeamMemberModal>
						</TeamMember>
					);
				})}
			</div>
			{/* partners */}
			<div className={grd.gridContainer} ref={membersRef}>
				{data.teamPartners.nodes.map((person, index) => {
					let groupName = (person.frontmatter.partners)? 'Cynosure Partners':null;
					if (
						activeFilter !== groupName
					) {
						return null;
					}
					person.frontmatter.name =
						person.frontmatter.first_name + " " + person.frontmatter.last_name;
					return (
						<TeamMember
							key={"team-member-" + person.id}
							className={hasNames(grd.col3, grd.colMd4, grd.colSm6)}
							image={
								<GatsbyImage
									image={getImage(person.frontmatter.image)}
									alt={person.frontmatter.name}
								/>
							}
							name={person.frontmatter.name}
							position={person.frontmatter.title_short}
							openFn={() => {
								setMemberOpen(index);
								document.body.classList.add("scrollLocked");
							}}
							closeFn={() => {
								setMemberOpen(-1);
								document.body.classList.remove("scrollLocked");
							}}
							nextFn={
								index + 1 === data.teamPartners.nodes.length
									? null
									: () => {
											setMemberOpen(index + 1);
									  }
							}
							prevFn={
								index === 0
									? null
									: () => {
											setMemberOpen(index - 1);
									  }
							}
							isOpen={index === memberOpen}>
							<TeamMemberModal
								name={person.frontmatter.name}
								position={person.frontmatter.title_long}
								linkedin={person.frontmatter.linkedin}
								image={
									<GatsbyImage
										image={getImage(person.frontmatter.image)}
										alt={person.frontmatter.name}
									/>
								}>
								<div
									dangerouslySetInnerHTML={{
										__html: person.html,
									}}></div>
							</TeamMemberModal>
						</TeamMember>
					);
				})}
			</div>
			{/* business */}
			<div className={grd.gridContainer} ref={membersRef}>
				{data.teamBusiness.nodes.map((person, index) => {
					let groupName = (person.frontmatter.business)? 'Business and Operations':null;
					if (
						activeFilter !== groupName
					) {
						return null;
					}
					person.frontmatter.name =
						person.frontmatter.first_name + " " + person.frontmatter.last_name;
					return (
						<TeamMember
							key={"team-member-" + person.id}
							className={hasNames(grd.col3, grd.colMd4, grd.colSm6)}
							image={
								<GatsbyImage
									image={getImage(person.frontmatter.image)}
									alt={person.frontmatter.name}
								/>
							}
							name={person.frontmatter.name}
							position={person.frontmatter.title_short}
							openFn={() => {
								setMemberOpen(index);
								document.body.classList.add("scrollLocked");
							}}
							closeFn={() => {
								setMemberOpen(-1);
								document.body.classList.remove("scrollLocked");
							}}
							nextFn={
								index + 1 === data.teamBusiness.nodes.length
									? null
									: () => {
											setMemberOpen(index + 1);
									  }
							}
							prevFn={
								index === 0
									? null
									: () => {
											setMemberOpen(index - 1);
									  }
							}
							isOpen={index === memberOpen}>
							<TeamMemberModal
								name={person.frontmatter.name}
								position={person.frontmatter.title_long}
								linkedin={person.frontmatter.linkedin}
								image={
									<GatsbyImage
										image={getImage(person.frontmatter.image)}
										alt={person.frontmatter.name}
									/>
								}>
								<div
									dangerouslySetInnerHTML={{
										__html: person.html,
									}}></div>
							</TeamMemberModal>
						</TeamMember>
					);
				})}
			</div>
			<ContactUsFooter ref={contactRef} />
		</Frame>
	);
};

export default TeamPage;

export const query = graphql`
	query TeamPageQuery {
		teamMembers: allMarkdownRemark(
			filter: {
				fileAbsolutePath: { regex: "/src/cms/data/team/" }
			}
			sort: { fields: frontmatter___last_name }
		) {
			nodes {
				id
				frontmatter {
					first_name
					last_name
					title_long
					title_short
					linkedin
					image {
						childImageSharp {
							gatsbyImageData(
								width: 360
								height: 360
								quality: 90
								transformOptions: { cropFocus: NORTH }
							)
						}
					}
				}
				html
			}
			placements: distinct(field: frontmatter___placement)
		}
		teamBoard: allMarkdownRemark(
			filter: {
				fileAbsolutePath: { regex: "/src/cms/data/team/" }
				frontmatter: { board: { eq: true } }
			}
			sort: { fields: frontmatter___board_placement }
		) {
			nodes {
				id
				frontmatter {
					first_name
					last_name
					title_long
					title_short
					linkedin
					board
					board_placement
					image {
						childImageSharp {
							gatsbyImageData(
								width: 360
								height: 360
								quality: 90
								transformOptions: { cropFocus: NORTH }
							)
						}
					}
				}
				html
			}
		}
		teamPartners: allMarkdownRemark(
			filter: {
				fileAbsolutePath: { regex: "/src/cms/data/team/" }
				frontmatter: { partners: { eq: true } }
			}
			sort: { fields: frontmatter___partners_placement }
		) {
			nodes {
				id
				frontmatter {
					first_name
					last_name
					title_long
					title_short
					linkedin
					partners
					partners_placement
					image {
						childImageSharp {
							gatsbyImageData(
								width: 360
								height: 360
								quality: 90
								transformOptions: { cropFocus: NORTH }
							)
						}
					}
				}
				html
			}
		}
		teamBusiness: allMarkdownRemark(
			filter: {
				fileAbsolutePath: { regex: "/src/cms/data/team/" }
				frontmatter: { business: { eq: true } }
			}
			sort: { fields: frontmatter___business_placement }
		) {
			nodes {
				id
				frontmatter {
					first_name
					last_name
					title_long
					title_short
					linkedin
					business
					business_placement
					image {
						childImageSharp {
							gatsbyImageData(
								width: 360
								height: 360
								quality: 90
								transformOptions: { cropFocus: NORTH }
							)
						}
					}
				}
				html
			}
		}
	}
`;
